
























































































































































import { Component } from 'vue-property-decorator';
import _ from 'lodash';
import { AxiosError } from 'axios';

import AppVue from '@/AppVue.vue';
import { CommunityExposureGridFilter, CommunityMatchExposureModel, CommunityExposureLookupModel } from '@/core/webapi';
import { getFirstValidationErrorMessage, ApiService, ReportLookupService } from '@/core/services';
import { InputTextareaCpt, ValidationSummaryCpt } from '@/core/components';
import {
  GridFactory,
  GridModel,
  GridFilter,
  GridPagerCpt,
  GridSelectFilterCpt,
  StudyOrgFilterCpt,
} from '@/core/components/grid';
import { QcStatusTagCpt } from '../components';
import { ReviewAndSaveDialogCpt, ExposuresGridHeaderCpt } from './components';
import { StudyTypes } from '@/core/constants';

@Component({
  components: {
    GridPagerCpt,
    GridSelectFilterCpt,
    InputTextareaCpt,
    ValidationSummaryCpt,
    StudyOrgFilterCpt,
    QcStatusTagCpt,
    ExposuresGridHeaderCpt,
    ReviewAndSaveDialogCpt,
  },
})
export default class CommunityExposureMatchesGridView extends AppVue {
  grid = {} as GridModel<CommunityMatchExposureModel>;
  lookups = {} as CommunityExposureLookupModel;
  model: CommunityMatchExposureModel = {
    isReadyForQc: false,
    requireReporterInfo: false,
    isQcComplete: false,
    modelState: null,
  } as any;
  showReporterDialog = false;
  showAnalystDialog = false;
  studyTypeId = StudyTypes.Community;

  get areFiltersSelected() {
    return !!this.grid.filter.organisationId && this.grid.filter.studyId;
  }

  async created() {
    this.grid = GridFactory.create({
      filterMethod: filter =>
        ApiService.communityExposures().filterMatchExposures(filter as CommunityExposureGridFilter),
      routeName: 'matchExposures',
      defaultFilterOverrides: new GridFilter(await this.initializeFilters()),
    });
  }

  async initializeFilters() {
    this.lookups = (await ApiService.communityExposures().getCommunityExposureFormLookupData()).data;

    let initialFilter = {
      search: '',
      organisationId: null,
      studyId: null,
      returnStatusId: null,
      qcStatusId: null,
    };

    return ReportLookupService.resolveStudyOrOrgIds(
      this.lookups,
      initialFilter,
      this.isAnalyst || this.isDevEnv,
      this.studyTypeId,
      this.isReporter,
    );
  }

  addExposure() {
    if (!this.grid.collection.items) {
      this.grid.collection.items = [];
    }

    this.grid.collection.items.unshift(
      new CommunityMatchExposureModel({
        organisationId: this.grid.filter.organisationId,
        studyId: this.grid.filter.studyId,
        isReadyForQc: false,
        requireReporterInfo: false,
        isQcComplete: false,
        modelState: null,
      } as any),
    );
  }

  async initializeSaveItem(model: CommunityMatchExposureModel) {
    (this.$refs.reviewAndSave as any).initializeSaveItem(model);
  }

  async saveItem(model: CommunityMatchExposureModel) {
    try {
      model.id
        ? await ApiService.communityExposures().updateMatchExposure(model)
        : await ApiService.communityExposures().createMatchExposure(model);

      this.$notify({
        title: this.$t('Success') as string,
        message: this.$t('Item saved') as string,
        type: 'success',
      });

      this.grid.update();
    } catch (error) {
      const validationMessage = getFirstValidationErrorMessage(error as AxiosError);
      if (validationMessage) {
        this.$notify({
          title: this.$t('Validation Failed') as string,
          message: validationMessage as string,
          type: 'error',
        });
      }
      _.extend((this.model as any).modelState, (model as any).modelState);
      this.$forceUpdate();
    }
  }

  async deleteItem(index: number) {
    if (!confirm(this.$t('Are you sure you want to delete this item?') as string)) {
      return;
    }

    const item = this.grid.collection.items![index];

    if (!!item.id) {
      await ApiService.communityExposures().deleteCommunityExposure(item.id);
      this.grid.update();
    } else {
      this.grid.collection.items!.splice(index, 1);
      this.$forceUpdate();
    }
  }
}
