





































































































































































































import { Component } from 'vue-property-decorator';
import _ from 'lodash';
import { AxiosError } from 'axios';

import AppVue from '@/AppVue.vue';
import { ExposureCollectionModel, ExposureLookupModel, ExposureGridFilter } from '@/core/webapi';
import { getFirstValidationErrorMessage, ApiService, ReportLookupService } from '@/core/services';
import { InputTextareaCpt, ValidationSummaryCpt } from '@/core/components';
import {
  GridFactory,
  GridModel,
  GridFilter,
  GridSearchCpt,
  GridPagerCpt,
  GridSelectFilterCpt,
  StudyOrgFilterCpt,
} from '@/core/components/grid';
import { QcStatusTagCpt } from '../components';
import { ReviewAndSaveDialogCpt, ExposuresGridHeaderCpt } from './components';
import { GameFormats, StudyTypes } from '@/core/constants';

@Component({
  components: {
    GridSearchCpt,
    GridPagerCpt,
    GridSelectFilterCpt,
    InputTextareaCpt,
    ValidationSummaryCpt,
    StudyOrgFilterCpt,
    QcStatusTagCpt,
    ReviewAndSaveDialogCpt,
    ExposuresGridHeaderCpt,
  },
})
export default class EliteExposuresGridView extends AppVue {
  grid = {} as GridModel<ExposureCollectionModel>;
  lookups = {} as ExposureLookupModel;
  model: ExposureCollectionModel = {
    isReadyForQc: false,
    requireReporterInfo: false,
    isQcComplete: false,
    modelState: null,
  } as any;
  showReporterDialog = false;
  showAnalystDialog = false;
  studyTypeId = StudyTypes.Elite;

  get areFiltersSelected() {
    return !!this.grid.filter.organisationId && this.grid.filter.studyId;
  }

  get showTournamentColumn() {
    if (!this.grid.collection?.items) {
      return false;
    }

    if (!this.grid.filter.studyId) {
      return !!this.isAnalyst;
    }

    const selectedStudy = ReportLookupService.getSelectedStudy(
      this.lookups,
      this.grid.filter as ExposureGridFilter,
      this.isReporter,
    );
    if (!selectedStudy) {
      return !!this.isAnalyst;
    }

    return selectedStudy.gameFormatId === GameFormats.Sevens;
  }

  get metric1() {
    return this.grid.collection?.items?.find(p => !!p.metric1)?.metric1;
  }

  get metric2() {
    return this.grid.collection?.items?.find(p => !!p.metric2)?.metric2;
  }

  get metric3() {
    return this.grid.collection?.items?.find(p => !!p.metric3)?.metric3;
  }

  async created() {
    this.grid = GridFactory.create({
      filterMethod: filter => ApiService.exposures().filterExposures(filter as ExposureGridFilter),
      routeName: 'exposures',
      defaultFilterOverrides: new GridFilter(await this.initializeFilters()),
    });
  }

  async initializeFilters() {
    this.lookups = (await ApiService.exposures().getExposureFormLookupData()).data;

    let initialFilter = {
      search: '',
      organisationId: null,
      studyId: null,
      returnStatusId: null,
      qcStatusId: null,
    };

    return ReportLookupService.resolveStudyOrOrgIds(
      this.lookups,
      initialFilter,
      this.isAnalyst || this.isDevEnv,
      this.studyTypeId,
      this.isReporter,
    );
  }

  addExposure() {
    if (!this.grid.collection.items) {
      this.grid.collection.items = [];
    }

    this.grid.collection.items.unshift(
      new ExposureCollectionModel({
        organisationId: this.grid.filter.organisationId,
        studyId: this.grid.filter.studyId,
        forDate: undefined as any,
        noOfSessions: 0,
        noOfInjuries: 0,
        isSaveable: true,
        isReadyForQc: false,
        requireReporterInfo: false,
        isQcComplete: false,
        modelState: null,
      } as any),
    );
  }

  async initializeSaveItem(model: ExposureCollectionModel) {
    (this.$refs.reviewAndSave as any).initializeSaveItem(model);
  }

  async saveItem(model: ExposureCollectionModel) {
    try {
      model.id
        ? await ApiService.exposures().updateExposureSimple(model)
        : await ApiService.exposures().createExposureSimple(model);

      this.$notify({
        title: this.$t('Success') as string,
        message: this.$t('Item saved') as string,
        type: 'success',
      });

      this.grid.update();
    } catch (error) {
      const validationMessage = getFirstValidationErrorMessage(error as AxiosError);
      if (validationMessage) {
        this.$notify({
          title: this.$t('Validation Failed') as string,
          message: validationMessage as string,
          type: 'error',
        });
      }
      _.extend((this.model as any).modelState, (model as any).modelState);
      this.$forceUpdate();
    }
  }

  async deleteItem(index: number) {
    if (!confirm(this.$t('Are you sure you want to delete this item?') as string)) {
      return;
    }

    const item = this.grid.collection.items![index];

    if (!!item.id) {
      await ApiService.exposures().deleteExposure(item.id);
      this.grid.update();
    } else {
      this.grid.collection.items!.splice(index, 1);
      this.$forceUpdate();
    }
  }
}
